import * as Sentry from '@sentry/nextjs';
import {
    buildSearchQuery,
    SearchQueryParams,
} from '../components/search/utils';
import { defaultSortByType, SearchItem } from '../components/search/types';
import logger from '../utils/logger';

export const getSearchContent = async (
    queryParams: SearchQueryParams
): Promise<SearchItem[]> => {
    const query = buildSearchQuery(queryParams);

    const url = `${process.env.SEARCH_SERVICE_URL}/search?${query}`;
    const options = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };

    let response;
    let tryCount = 0;
    while (!response && tryCount < 3) {
        tryCount++;
        try {
            response = await fetch(url, options);
            if (!response.ok) {
                const e = Error(
                    `Bad response from search endpoint: ${response.status}`
                );
                throw e;
            }
        } catch (e) {
            logger.error(
                `Failed request to search service ${tryCount} time(s). Error: ${e}`
            );
            if (tryCount === 3) {
                Sentry.captureException(e);
                throw e;
            }
        }
    }
    if (!response) {
        throw Error('Got empty response from search service 5 times in a row.');
    }
    const data: SearchItem[] = await response.json();
    return data;
};

// Used in get-all-search-content.preval for building search filters.
export const getAllSearchContent = async (): Promise<SearchItem[]> => {
    const queryParams: SearchQueryParams = {
        searchString: '',
        sortBy: defaultSortByType,
    };
    return await getSearchContent(queryParams);
};

export default getAllSearchContent;
